import React, {Component} from 'react';
import './App.css';
import AppBarCP from './AppBarCP';
import {saveProfile, currentUser} from './db.js'
import { TextField, Button, Box } from '@material-ui/core';


export class Profile extends Component {
    constructor(props) {
        super(props);
        const user = currentUser()
        this.state = {
            firstName: user.firstName, 
            lastName: user.lastName,
            oldPassword: '',
            newPassword1: '',
            newPassword2: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.modified = false;
    }

    render() {
            return (
                <div>
                    <AppBarCP back={this.props.back} text='Benutzerprofil' />
                    <div className='Body'>
                        <div><TextField id='firstName' autoFocus={true} fullWidth={true} label='Vorname' value={this.state.firstName} onChange={this.handleInputChange} /></div>
                        <div><TextField id='lastName' fullWidth={true} label="Nachname" value={this.state.lastName} onChange={this.handleInputChange} /></div>
                        <div><TextField id='oldPassword' fullWidth={true} label="Altes Passwort" value={this.state.oldPassword} onChange={this.handleInputChange} autoComplete='current-password' type="password" /></div>
                        <div><TextField id='newPassword1' fullWidth={true} label="Neues Passwort" value={this.state.newPassword1} onChange={this.handleInputChange} type="password" /></div>
                        <div><TextField id='newPassword2' fullWidth={true} label="Neues Passwort wiederholen" value={this.state.newPassword2} onChange={this.handleInputChange} type="password" /></div>
                        <Box pt={1}><Button variant="contained" onClick={this.save} disabled={this.passwordsAgree()}>Speichern</Button></Box>
                   </div>
                </div>
            );
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.id;
        this.setState({[name]: target.value});
        this.modified = true;
    }

    passwordsAgree() {
        if(this.state.oldPassword.length > 0 || this.state.newPassword1.length > 0 || this.state.newPassword2.length > 0)
            return this.state.oldPassword.length === 0 || this.state.newPassword1.length === 0 || this.state.newPassword2.length === 0 || this.state.newPassword1 !== this.state.newPassword2
        return false
    }

    save = () => {
        saveProfile({ firstName: this.state.firstName, lastName: this.state.lastName }, this.state.oldPassword, this.state.newPassword1)
    }
}

export default Profile
