import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { Typography, Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { logout} from './db.js'



export class AppBarCP extends Component  {

    txtStyle = { flexGrow: 1, };

    state = { anchorEl: null }

    menuClick = (event) => { this.setState({anchorEl:event.currentTarget })};
    menuClose = () => { this.setState({anchorEl:null})};
    renderBackButton() {
        if (this.props.back){
            return (<IconButton edge="start" color="inherit" aria-label="Back" onClick={() => this.props.back()}>
              <ArrowBackIcon />
            </IconButton>)
        }
    }

  render() {
    return (
      <div >
        <AppBar position="static">
          <Toolbar>
          {this.renderBackButton()}
            <Typography style={this.txtStyle}>
                {this.props.text}
            </Typography>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" edge="end" color="inherit" onClick={this.menuClick}>
              <MenuRoundedIcon/>
            </IconButton>
            <Menu id="simple-menu" anchorEl={this.state.anchorEl} keepMounted open={Boolean(this.state.anchorEl)} onClose={this.menuClose} >
                { this.props.appMenu? this.props.appMenu.map( (it => <MenuItem key={it} onClick={() => this.handleExtra(it)}>{it}</MenuItem>)): []}
                <MenuItem onClick={() => logout()}>Abmelden</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
  );};
  /* <MenuItem onClick={this.menuClose}>Profile</MenuItem> */
  handleExtra(item){
    this.menuClose()
    this.props.menuHandler(item)
  }

}
export default AppBarCP;

