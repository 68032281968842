import React, { Component } from 'react';
import { EditBook } from './EditBook'
import { CookBook } from './CookBook'
import { currentUser, clearActiveSteps } from './db'
import {Button} from './Button';
import AppBarCP from './AppBarCP';
import Profile from './Profile';
import About from './About';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: 1,
            activeSteps: currentUser()?.activeSteps
        }
        this.menuHandler = this.menuHandler.bind(this);
        this.removeActiveRecipe = this.removeActiveRecipe.bind(this);
    }

    render() {
        if (this.state.show === 2) return <CookBook back={this.home}/>;
        else if (this.state.show === 3) return (<EditBook back={this.home} />);
        else if(this.state.show === 4) return (<CookBook back={this.home} active={this.state.activeSteps} />);
        else if(this.state.show === 5) return (<Profile back={this.home} />);
        else if(this.state.show === 6) return (<About back={this.home} />);
        else return this.welcome();
    }

    userProfileMenuItem = 'Benutzerprofil'
    removeActiveRecipeMenuItem = "Aktives Rezept beenden"
    aboutMenuItem = "Über Cookpilot"
    appMenuItems2 = [this.userProfileMenuItem, this.removeActiveRecipeMenuItem, this.aboutMenuItem]
    appMenuItems1 = [this.userProfileMenuItem, this.aboutMenuItem]

    appMenu() {
        if(this.state.activeSteps) return this.appMenuItems2
        return this.appMenuItems1
    }

    menuHandler(menuItem) {
        console.log(JSON.stringify(menuItem))
        if(menuItem === this.userProfileMenuItem) this.setState({ show: 5 })
        else if(menuItem === this.removeActiveRecipeMenuItem) this.removeActiveRecipe()
        else if(menuItem === this.aboutMenuItem) this.setState({ show: 6 })
    }

    removeActiveRecipe(){
        clearActiveSteps()
        this.setState({ activeSteps: null })
    }

    txtStyle = { flexGrow: 1, };
    iconStyle = { width: 64, height: 64, };


    welcome() {
        return (
            <div >
                <AppBarCP back={this.out} text='Einfach besser kochen!' appMenu={this.appMenu()} menuHandler={this.menuHandler}/>
                <div className='Body'>
                    <p>Sieh Dir einige Rezepte an und lasse dich von CookPilot interaktiv durch die Arbeitschritte f&uuml;hren.</p>
                    <p>So geht es: Die einzelnen Schritte, werden in einer detaillierten Liste angezeigt. Die Farben sagen Dir wo Du stehst.</p>
                    <ul>
                        <li>Hervorgehobene Schritte sind als n&auml;chstes dran. Markiere sie durch antippen wenn sie fertig sind.</li>
                        <li>Dunkelgraue Schritte k&ouml;nnen erst sp&auml;ter begonnen werden.</li>
                        <li>Blau unterstrichene Schritte dauern einige Zeit. (Z.B. etwas soll kochen) Starte die Uhr durch antippen. Dann wird Dir die Restzeit und ein Fortschrittsbalken angezeigt</li>
                        <li>Graue Schritte sind erledigt.</li>
                    </ul>
                    <Button variant="contained" onClick={this.showRecipes}>Rezepte ausw&auml;hlen und kochen.</Button>
                    <p>Erfasse eigene Rezepte, so dass sie von CookPilot gesteuert werden k&ouml;nnen.</p>
                    <Button variant="contained" onClick={this.showEditor}>Rezepte anlegen und bearbeiten.</Button>
                    {this.activeSteps()}
                </div>
            </div>)
    }

    activeSteps() {
        if (this.state.activeSteps) return (
            <div>
                <p> Ein Rezept ist aktiv: </p>
                <Button variant="contained" onClick={this.continueRecipe}>{this.state.activeSteps.name} weiterkochen.</Button>
                {" Oder: "}
                <Button variant="contained" onClick={this.removeActiveRecipe}>Beenden.</Button>
            </div>
        )

    }

    home = () => this.setState({
        show: 1,
        activeSteps: currentUser()?.activeSteps

    });

    showEditor = () => {
        this.setState({ show: 3 });
    };

    showRecipes = () => {
        this.setState({ show: 2, activeSteps: null });
    }

    continueRecipe = () => {
        this.setState({ show: 4 });
    }
}

export default App;
