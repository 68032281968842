import React, {Component} from 'react';
import './App.css';
import {withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Button} from './Button';
import {CookStepBox} from './CookStepBox';
import {Box, Typography} from '@material-ui/core';

export class CookStep extends Component {

    NarrowBorderLinearProgress = withStyles({
        root: {
            height: 12,
        },
        bar: {
            borderRadius: 20,
        },
    })(LinearProgress);

    MarginButton = withStyles({
        root: {
            marginBottom: 9,
            marginTop: 9,
        }
    })(Button);

    LighterBox = withStyles({
        root: {
            color: 'dimgray',
        }
    })(Box)

    running = false
    txtStyle = {flexGrow: 1,}
    durationSeconds = 0
    endTone = new Audio("chime.mp3")


    constructor(props) {
        super(props);
        props.step.durationSeconds = Math.round(props.step.duration * 60)
        this.state = {
            remaining: props.step.durationSeconds, // remainder is calculated in seconds
            percentDone: 0,
            hintShowing: true
        };
    }

    render() {
        const step = this.props.step;
        const completion = step.state;
        //console.log("StepRow render: " + this.props.step.text)

        if (completion === 0) { // ready or in progress
            if (this.props.step.duration > 0) { //1
                const remainingMinutes = Math.trunc(this.state.remaining / 60)
                const remainingSeconds = this.state.remaining % 60
                //console.log(this.state.remaining)
                return (
                    <CookStepBox hint={this.props.step.hint} image={this.props.step.thumbnail}>

                        <Box flexDirection="col" flexGrow={1}>
                            <Box display='flex' flexDirection="row" flexWrap="nowrap">
                                <this.MarginButton variant="contained" onClick={this.start} fullWidth>
                                    <Typography style={this.txtStyle}>{step.text}&nbsp;
                                        ({remainingMinutes}{remainingSeconds !== 0 ? ':' + remainingSeconds : ''})
                                    </Typography>
                                </this.MarginButton>
                            </Box>
                            <this.NarrowBorderLinearProgress variant="determinate" value={this.state.percentDone}/>
                        </Box>
                    </CookStepBox>

                )

            } else {
                return (
                    <CookStepBox hint={this.props.step.hint}>
                        <this.MarginButton variant="contained" fullWidth
                                           onClick={this.done}>{step.text}</this.MarginButton>
                    </CookStepBox>
                )
            }
        } else if (completion === 1) { // not ready
            return (
                <CookStepBox hint={this.props.step.hint}>
                    <Typography style={this.txtStyle}>{step.text}</Typography>
                </CookStepBox>
            )


        }
        if (completion === 2) { // done
            return (<this.LighterBox>{step.text}</this.LighterBox>);
        }
    }

    done = () => {
        this.props.done(this.props.step);
        this.setState({completion: 2, remaining: this.state.remaining});
    };

    start = () => {
        const s = this.props.step;

        if (this.running) {
            console.log('now paused: ' + s.text)
            this.running = false
            clearInterval(s.timer);
            s.timer = null
        } else {
            this.running = true;
            const stepRow = this;
            if (s.secondsElapsed) {  // restart: subtract elapsed seconds from timer.
                this.timerStart = Date.now() - s.secondsElapsed*1000
                this.setState({percentDone: s.secondsElapsed / s.durationSeconds * 100})
            } else { // initial start
                s.secondsElapsed = 1
                this.timerStart = Date.now()
                this.setState({percentDone: 1})
            }
            s.timer = setInterval(frame, 1000); // update screen every second

            function frame() {
                if (!stepRow.running) {
                    //console.log('is paused')
                    return
                }
                if (s.secondsElapsed >= s.durationSeconds - 1 && s.secondsElapsed < s.durationSeconds + 60) {
                    stepRow.endTone.play();
                    clearInterval(s.timer);
                    s.timer = null
                    stepRow.done(s);
                } else {
                    s.secondsElapsed = Math.round((Date.now() - stepRow.timerStart) / 1000)
                    let percent = s.secondsElapsed / s.durationSeconds * 100;
                    stepRow.setState({remaining: s.durationSeconds - s.secondsElapsed, percentDone: percent});
                }
            }
        }
    }


}

export default CookStep;
