import React from 'react';
import App from './App'
import { TextField, Button, FormLabel, Box } from '@material-ui/core';
import firebase from 'firebase';
import { setUser, passwordReset } from './db';

export default class SignInScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isSignedIn: false, // Local signed-in state.
            email: '',
            pwd: '',
            errorMessage: null
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
        this.loginError = this.loginError.bind(this);
        this.userReceived = this.userReceived.bind(this)

        firebase.auth().onAuthStateChanged(this.onAuthStateChanged)
    }


    render() {
        if(this.state.loading) {
            return <p>Startet...</p>
        } else if(!this.state.isSignedIn) {
            if(this.state.errorMessage) console.log("Login failed message: " + this.state.errorMessage)
            return (
                <form onSubmit={this.handleSubmit} noValidate className='Body'>
                    <Box m={1}>
                        {this.state.errorMessage && <div><FormLabel error='true'>{this.state.errorMessage}</FormLabel><br /></div>}
                        <TextField id='email' label='Email' value={this.state.email} onChange={this.handleInputChange} className='TextField' autoComplete='email' autoFocus={true} />
                        <br />
                        <TextField id='pwd' label="Password" value={this.state.pwd} onChange={this.handleInputChange} className='TextField' autoComplete='current-password' type="password" />
                        <br />
                        <Box pt={2}><Button type="submit" variant="contained">Login</Button></Box>
                        <Box pt={2}><Button onClick={()=>passwordReset(this.state.email)} variant="contained">Passwort zurücksetzen</Button></Box>
                    </Box>
                </form>)
        }
        else
            return <App parent={this} />
    }

    handleInputChange(event) {
        if(this.state.loading) return
        this.setState({
            [event.target.id]: event.target.value,
            errorMessage: null
        });
        //console.log("input " + event.target.id + " = " + event.target.value)
    }

    handleSubmit(event) {
        event.preventDefault()
        console.log("pwd submit " + this.state.email)

        if(this.state.loading) return
        this.setState({ 'loading': true })

        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.pwd).catch(this.loginError);
    }

    loginError(error) {
        this.setState({ 'errorMessage': error.message, loading: false });
        console.log("signInWithEmailAndPassword " + this.state.errorMessage + " code=" + error.code)
    }

    onAuthStateChanged(user) {
        if(user) {
            console.log("signed in uid= " + user.uid + " email: " + user.email);
            setUser(user.email, this.userReceived)

            this.setState({ isSignedIn: true, uid: user.uid, pwd: '', errorMessage: null })
        } else {
            console.log("signed out");
            this.setState({ isSignedIn: false, uid: '', email: '', pwd: '', errorMessage: null, 'loading': false })
        }
    }

    userReceived() {
        console.log("userReceived");
        this.setState({ 'loading': false })
    }

    componentWillUnmount() {
        firebase.auth().onAuthStateChanged()
    }
}