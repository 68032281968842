import React, { Component } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

export class CookStepBox extends Component {
    state = { hintShowing: false }
    render() {
        if(!this.props.hint && !this.props.image)
            return this.props.children
        if(this.state.hintShowing)
            return (<Box flexDirection='col'>
                <Box display='flex' flexDirection="row" flexWrap="nowrap" >
                    {this.props.children}
                    <IconButton onClick={() => { this.setState({ hintShowing: !this.state.hintShowing }) }} size='small'><ArrowDropUpIcon /></IconButton>
                </Box>
                {this.hint()}
            </Box>)
        return (
            <Box display='flex' flexDirection="row" flexWrap="nowrap" >
                {this.props.children}
                <IconButton onClick={() => { this.setState({ hintShowing: !this.state.hintShowing }) }} size='small'><ArrowDropDownIcon /></IconButton>
            </Box>)
    }

    hint() {
        if(this.props.image && this.props.hint) {
            return (
                <Box display='flex' flexDirection="row" flexWrap="nowrap" >
                    <img src={this.props.image} alt="" />
                    <Typography>{this.props.hint}</Typography>
                </Box>)
        } else if(this.props.hint) {
            return <Typography>{this.props.hint}</Typography>
        } else if(this.props.image) {
            return <img src={this.props.image} alt="" />           
        }

    }

}

export default CookStepBox;
