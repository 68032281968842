import React, { Component } from 'react';
import './App.css';
import EditRecipe from './EditRecipe';
import { getOwnTasks, loadSteps, addTask, currentUser } from './db.js'
import ButtonBase from '@material-ui/core/ButtonBase';
import AppBarCP from './AppBarCP';
import {DefaultAddButton} from './FloatingActionButton';

export class EditBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            recipes: null,
            recipe: null,
        steps: null
        };
        this.newRecipeStored = this.newRecipeStored.bind(this);
    }

    componentDidMount() {
        const ownTasks = getOwnTasks()
        this.setState({ recipes: ownTasks, loading: false });
        console.log("loaded own tasks:  " + JSON.stringify(ownTasks))
    }

    render() {
        if (this.state.steps) {
            console.log("ed recipe: " + JSON.stringify(this.state.recipe))
            return <EditRecipe recipe={this.state.recipe} steps={this.state.steps} finished={this.afterRecipeEdit}/>
        } else if(this.state.loading) {
            console.log("ed recipe: loading")
            return <span>Startet...</span>
        } else {
            return this.renderRecipeList();
        }
    }
    renderRecipeList() {
        return (
            <div >
                <AppBarCP back={this.out} text='Bitte w&auml;hle das Rezept zur Bearbeitung.' />
                <div className='Body'>
                    {Object.entries(this.state.recipes).map((k) => <p key={k[0]} ><ButtonBase variant="text" onClick={() => this.loadRecipe(k[0])} style={{textAlign: 'left',}}>{k[1]}</ButtonBase></p>)}
                    <DefaultAddButton onClick={() => this.createRecipe()} />
                </div>
            </div>
        );
    }

    createRecipe() {
        addTask({ name: 'Neues Rezept ' + currentUser().taskSequence, description: 'Lecker Lecker', public: false }, this.newRecipeStored)
    }

    newRecipeStored(r) {
            this.setState({recipe:r, recipes:this.state.recipes, steps:[]})
        console.log("recipeCreated: " + JSON.stringify(this.state.recipe))
    }

    loadRecipe = (rId) => {
        console.log("loadRecipe " + JSON.stringify(this.state.recipes[rId]))
        this.setState({ loading: true });
        loadSteps(rId, this)
    }

    recipeLoaded(recipe, steps) {
        console.log("recipeLoaded: " + steps.length + " steps")

        this.setState({ loading: false, steps, recipe})
    }

    afterRecipeEdit = () => {
        console.log("afterRecipeEdit: " + this.state.recipe.name)
        const recipeUpdate = this.state.recipes
        recipeUpdate[this.state.recipe.id] = this.state.recipe.name

        this.setState({recipe: null, steps:null, recipes: recipeUpdate});
        //this.forceUpdate();
    }
    out = () => this.props.back();
}

export default EditBook;
