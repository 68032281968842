import firebase from 'firebase/app'
import 'firebase/firebase-firestore'
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA6T7qaIqRRxfhk6CJ6se207BDUgiLy-gE",
    authDomain: "recipeserver-190708.firebaseapp.com",
    databaseURL: "https://recipeserver-190708.firebaseio.com",
    projectId: "recipeserver-190708",
    storageBucket: "recipeserver-190708.appspot.com",
    messagingSenderId: "1092789753318",
    appId: "1:1092789753318:web:0a881dae9dc46d3d"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore()
var storageRef = firebase.storage().ref();
var tasks = db.collection("tasks")
var steps = db.collection("steps")
var timings = db.collection("timings")
var userData = null
var userRef = null

export function loadTasks(target) {
    log("loadTasks")
    let ret = new Map()
    const query = tasks.where("public", "==", true);
    query.get().then(coll => {
        coll.forEach(r => {
            ret.set(r.id, r.data())
        })
        logo("loadTasks/loaded", {size: ret.size})
        target.recipeListLoaded(ret)
    }, error => {
        error("loadTasks", error);
    })
}

export function getOwnTasks() {
    log("getOwnTasks " + userData.ownRecipes)
    return userData.ownRecipes
}


export function loadSteps(rId, target) {
    steps.doc(rId).get().then(s => {
        log("loadSteps", rId)
        var steps = s.data().steps
        if (!steps) steps = [];
        tasks.doc(rId).get().then(r => {
            log("loadTask", rId)
            target.recipeLoaded(r.data(), steps)
        })
    })
}

function log(path, ...vals) {
    console.log(path, vals)
}

function error(path, ...vals) {
    console.error('error', path, vals)
    dbLog(path)
}

function logo(msg, d) {
    log(msg + JSON.stringify(d))
}


export function addTask(r, newTaskStored) {
    const newKey = userData.key + userData.taskSequence
    log('addTask ', r.name, newKey)
    r.id = newKey
    r.creator = userData.email
    tasks.doc(newKey).set(r).then((ref) => {
        log('addTask/added', newKey)
        steps.doc(newKey).set({'name': r.name}).then(
            log("addTask/steps/added", r.id, r.name))
            .catch((err) => {
                error("addTask/steps/added", r.id, err)
            })
        userData.taskSequence++
        updateOwnRecipe(newKey, r.name)
        newTaskStored(r)
    })
        .catch((err) => {
            error('addTask/added', err)
        })
}

export function saveTask(r) {
    log('saveTask ', r.name, r.id)
    tasks.doc(r.id).set(r).then(() => {
        log("saveTask/updated", r.name, r.id)
        tasks.doc(r.id).set(r)
        steps.doc(r.id).update({name:r.name})
        updateOwnRecipe(r.id, r.name)
    })
}

function updateOwnRecipe(id, name) {
    userData.ownRecipes[id] = name
    console.log("updateOwnRecipe " + JSON.stringify(userData))
    userRef.set(userData).then(() => {
        log("updateOwnRecipe", userData.ownRecipes[id])
    })
        .catch((err) => {
            error("updateOwnRecipe", userData.ownrecipes, err)
        })
}

function updateImageSequence() {
    const ret = userData.imageSequence + 1
    userData.imageSequence = ret
    console.log("updateImageSequence " + ret)
    userRef.update({imageSequence: ret}).catch((err) => {
            error("updateImageSequence", userData, err)
        })
    return ret
}

export function logout() {
    firebase.auth().signOut().then(function () {
        console.log("User is signing out")
    }).catch(function (error) {
        console.log("User signout failed" + JSON.stringify(error))

    });
}

export function saveSteps(r, changedSteps) {
    log('saveSteps', r, changedSteps);
    steps.doc(r.id).update({'steps': changedSteps}).then(() => {
        console.log("Steps updated(r=" + r.id + ")")
    })
        .catch((err) => {
            error("saveSteps", r, changedSteps, err)
        })
}


export function setUser(u, userReceived) {
    console.log('setUser')
    userRef = db.collection('users').doc(u)
    userRef.get().then(r => {
        console.log("setUser data = " + JSON.stringify(r.data()))
        userData = r.data()
        if (!userData) {
            userData = {email: u, ownRecipes: {}, imageSequence: 0, taskSequence: 1, key: genKey(u)}
            userRef.set(userData)
        }
        dbLog('login')
        userReceived()
    })
}

function dbLog(txt) {
    const d = new Date()
    const key = '' + d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + '-' + d.getHours() + '-' + d.getMinutes() + '-' + d.getSeconds() + '-' + d.getMilliseconds()
    userRef.collection('log').doc(key).set({t: txt})
}

function genKey(email) {
    const at = email.indexOf('@')
    const name = email.substring(0, at)
    const dot = name.indexOf('.')
    if (dot > 1) {
        return name.charAt(0) + name.charAt(dot + 1) + name.charAt(name.length - 1)
    } else {
        return name.substring(0, 1) + name.charAt(name.length - 1)
    }
}

export function currentUser() {
    return userData
}

export function clearActiveSteps() {
    delete userData.activeSteps
    userRef.update({
        activeSteps: firebase.firestore.FieldValue.delete()
    })
        .then(() => {
            console.log("clearActiveSteps/done");
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("clearActiveSteps: ", error);
        })
}

export function saveCookState(steps) {
    steps.time = Date.now()
    //console.log("saveCookState " + JSON.stringify(steps))
    userData.activeSteps = steps
    userRef.set(userData).then(() => {
        //console.log("saveCookState/saved")
    })
        .catch((err) => {
            error("saveCookState", steps, err)
        })
}

export function saveStepTime(sessionKey, timing){
    timings.doc(sessionKey).set(timing).catch((err) => {
            error('FAIL saveStepTime: ' + sessionKey, err)
        })

}

export function createSessionKey(recipeKey) {
    if (!userData.sessionSequence) userData.sessionSequence = 0
    userData.sessionSequence++
    userRef.update({ sessionSequence: userData.sessionSequence }).catch((err) => { error("createSessionKey", userData, err) })
    return userData.key + '_' + recipeKey+'_'+ userData.sessionSequence
}

export function storeImage(file, imageSaved) {
    const imageNum = updateImageSequence()
    const name = userData.key + '_' + imageNum
    var reader = new FileReader()   //

    reader.onload = (ev) => {
        const img = new Image()
        img.src = ev.target.result
        img.addEventListener('load', (ev) => {
            console.log("image loaded w=" + img.naturalWidth + " h=" + img.naturalHeight)

            scale(img, 256, 64)
                .then((blob) => saveImage(blob, name, imageSaved, 'thumbnail'))

            scale(img, 1920, 1080)
                .then((scaledImage) => saveImage(scaledImage, name, imageSaved, 'image'))
        })
    }
    reader.readAsDataURL(file)
}


function saveImage(img, name, imageSaved, prop) {
    const fileName = name + '_' + prop + '.jpg'
    console.log("saveImage " + fileName)
    const ref = storageRef.child(fileName)

    ref.put(img).then(function (snapshot) {
        console.log('Uploaded ' + fileName);
        ref.getDownloadURL().then(function (downloadURL) {
            console.log('File available at ' + fileName + " " + downloadURL)
            imageSaved(prop, downloadURL, name)
        })
    })
}


export function deleteImageFromDb(imageKey) {
    function deleteImg(path: string) {
        console.log('delete from storage: '+ path)
        const thumbRef = storageRef.child(path)
        thumbRef.delete().then(
            v => {
                console.log('thumbnail deleted ' + path)
            },
            reason => {
                console.log('thumbnail not deleted ' + path + ' reason: ' + reason)
            })
    }

    if (imageKey) {
        deleteImg(imageKey + '_thumbnail.jpg');
        deleteImg(imageKey + '_image.jpg');
    } else {
        console.log('deleteImageFromDb: but no imageKey')
    }
}


function scale(img, maxWidth, maxHeight) {

    const oriWidth = img.naturalWidth
    const oriHeight = img.naturalHeight;

    if (oriHeight > maxHeight || oriWidth > maxWidth) {
        const scale = Math.min(maxHeight / oriHeight, maxWidth / oriWidth)
        const scaledWidth = oriWidth * scale;
        const scaledHeight = oriHeight * scale;
        const c = document.createElement('canvas');
        c.width = scaledWidth
        c.height = scaledHeight
        const ctx = c.getContext('2d');

        ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight)
        return new Promise(f => c.toBlob(f, "image/jpeg", 0.7))
    } else {
        return new Promise(f => f(toBlob(img.src, img.dataTYPE)))
    }
}

function toBlob(dataURI, dataTYPE) {
    var binary = atob(dataURI.split(',')[1]), array = [];
    for (var i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
    return new Blob([new Uint8Array(array)], {type: dataTYPE});
}

export function saveProfile(userChanges, oldPwd, pwd) {
    console.log(JSON.stringify(userChanges))
    userRef = db.collection('users').doc(userData.email)
    userRef.update(userChanges)

    if (pwd) {
        var user = firebase.auth().currentUser;
        firebase.auth().signInWithEmailAndPassword(userData.email, oldPwd).then(() => {
            user.updatePassword(pwd).then(function () {
                console.log('OK saveProfile/updatePassword: ' + userData.email)
            }).catch((err) => {
                error('FAIL saveProfile/updatePassword: ' + userData.email, err)
            })
        }).catch((err) => error("FAIL saveProfile/validatePassword", err));
    }
}

export function passwordReset(email) {
    log('passwordReset', email)
    return firebase.auth().sendPasswordResetEmail(email)
}






