import React, {Component, Fragment} from "react";
import {
    Box,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import {Cell, DefaultAddButton, NCell} from './FloatingActionButton';
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import DeleteIcon from '@material-ui/icons/Delete';
import {storeImage, deleteImageFromDb} from "./db";

export default class EditStep extends Component {
    constructor(props) {
        super(props)

        this.modified = false
        this.imagesToSave = 0
        this.state = EditStep.assembleState(props)

        console.log("EditRecipeSteps state: " + this.props.step.id)

        this.handleDependencyChange = this.handleDependencyChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleNumberInputChange = this.handleNumberInputChange.bind(this)
        this.addRow = this.addRow.bind(this);
        this.done = this.done.bind(this);
        this.imageUploaded = this.imageUploaded.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.props.onParentBack(this.done)
    }

    static assembleState(props) {
        const tState = {
            text: props.step.text,
            hint: nonull(props.step.hint),
            image: props.step.image,
            thumbnail: props.step.thumbnail,
            imageKey: props.step.imageKey,
            duration: nonull(props.step.duration),
            stepId: props.step.id,
            waitForImageSave: false,
        };
        props.steps.forEach((s) => {
            tState[s.id] = false
        });
        props.step.after.forEach((d) => tState[d] = true);
        tState.steps = Array.from(props.steps)
        if (props.step.id === tState.steps.length) // new step add
            tState.steps.push(props.step)
        return tState
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.stepId !== nextProps.step.id)
            return EditStep.assembleState(nextProps)
        else
            return {}
    }

    render() {
        return (
            <Fragment>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead onClick={() => this.props.back(null)}>
                            <TableRow style={{backgroundColor: 'lightgray'}}>
                                <Cell>Nr</Cell>
                                <Cell style={{width: '65%'}}>Text</Cell>
                                <Cell align="right" style={{width: '25%'}}>Dauer</Cell>
                                <Cell align="right" style={{width: '10%'}}>Vorher</Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.steps.map((item, index) => (this.renderRow(item, index)))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <DefaultAddButton onClick={this.addRow}/>
            </Fragment>
        );
    }

    renderRow(item, index) {
        if (item !== this.props.step) return (
            <TableRow onClick={this.done} key={item.id}>
                <NCell scope="row">{index + 1}</NCell>
                <NCell>{item.text}</NCell>
                <NCell align="right">{item.duration}</NCell>
                <NCell align="right" style={{paddingRight: 1}}><Checkbox
                    checked={this.state[item.id]}
                    color="primary"
                    size="small"
                    onClick={this.handleDependencyChange}
                    id={index.toString()}/></NCell>
            </TableRow>)
        else return (
            <TableRow key={item.id}>
                <Cell colSpan={4}>
                    {this.showWaitMessage()}
                    <TextField id='text' autoFocus={true} fullWidth={true} label='Schritt:' value={this.state.text}
                               onChange={this.handleTextChange} className='TextField'/>
                    <TextField id='hint' fullWidth={true} label="Hinweise:" multiline value={this.state.hint}
                               onChange={this.handleInputChange} className='TextField'/>
                    <TextField id='duration' fullWidth={true} label="Dauer (minutes):" value={this.state.duration}
                               onChange={this.handleNumberInputChange} className='TextField'/>
                    <Box display='flex'>
                        <Box flexGrow={1}>
                        {this.showImage()}
                        <input hidden accept="image/*" id="icon-button-photo" onChange={this.handleCapture}
                               type="file"/>
                        <label htmlFor="icon-button-photo">
                            <IconButton color="primary" component="span"> <PhotoCamera/> </IconButton>
                        </label>
                        </Box>
                        <IconButton color="primary" component="span" onClick={this.props.deleteStep}> <DeleteIcon/> </IconButton>
                    </Box>
                </Cell>
            </TableRow>
        )
    }


    showWaitMessage() {
        if (this.state.waitForImageSave > 0) {
            return <p>Bitte warten. Das Bild wird hochgeladen.</p>
        }
    }

    handleDependencyChange(ev) {
        const target = ev.target;
        const id = target.id;

        console.log("EditRecipeSteps.handleDependencyChange(id=" + id + ", value= " + target.checked + ")");
        this.setState({[id]: target.checked});
        this.modified = true;
        ev.stopPropagation()
    }

    handleInputChange(event) {
        this.setState({[event.target.id]: event.target.value});
        this.modified = true;
    }

    handleTextChange(event) {
        this.modified = true;
        let t = event.target.value
        let ts = t.split(". ")
        if (ts.length < 3)
            this.setState({text: t})
        else {
            this.props.multiStepPaste(ts)
        }
    }

    handleNumberInputChange(event) {
        const target = event.target;
        const val = target.value;
        const name = target.id;
        //console.log("EditStep.handleNumberInputChange(name=" + name + ", value= " + val + ")");
        if (!/^\d*[.,]?\d*$/.test(val)) return;

        this.setState({[name]: target.value});
        this.modified = true;
    }

    handleCapture = ({target}) => {
        const file = target.files[0]
        console.log("EditStep/handleCapture " + file.name)
        this.imagesToSave += 2 // need to wait for 2 images (image + thumbnail). Images from other steps might also be loading
        this.modified = true
        storeImage(file, this.imageUploaded)
    };

    imageUploaded(prop, url, imageKey) {
        if (this.state.imageKey && imageKey !== this.state.imageKey)
            deleteImageFromDb(this.state.imageKey) // delete if there already was a previous image
        this.imagesToSave--
        this.setState({[prop]: url, imageKey, waitForImageSave: this.state.waitForImageSave && this.imagesToSave > 0})
    }

    showImage() {
        if (this.state.thumbnail) {
            return <Fragment><img src={this.state.thumbnail} alt="depicting step"/><IconButton color="primary" component="span" onClick={this.deleteImage}> <DeleteIcon/> </IconButton></Fragment>
        }
    }

    addRow() {
        console.log("editSwitch " + this.stepId)
        this.done()
        this.props.addRow()
    }
    deleteImage(){
        this.modified = true
        deleteImageFromDb(this.state.imageKey)
        this.setState({imageKey:null, thumbnail: null, image:null})
    }

    done() {
        console.log("Stop Editing: modified=" + this.modified + " imageWait=" + this.imagesToSave)
        if (this.modified) {
            if (this.imagesToSave > 0) {
                this.setState({waitForImageSave: true})
            } else {
                const newDependencies = []
                this.props.steps.forEach((s) => {
                    if (this.state[s.id])
                        newDependencies.push(s.id)
                })
                const ret = {
                    id: this.props.step.id,
                    text: this.state.text,
                    hint: this.state.hint,
                    duration: this.state.duration,
                    after: newDependencies
                };
                if (this.state.duration) ret.duration = this.state.duration
                if (this.state.thumbnail) ret.thumbnail = this.state.thumbnail; else delete ret.thumbnail
                if (this.state.image) ret.image = this.state.image; else delete ret.image;
                if (this.state.imageKey) ret.imageKey = this.state.imageKey; else delete ret.imageKey;

                this.props.back(ret)
            }
        } else
            this.props.back(null)
    }
}
function nonull(s) {
    if (s == null)
        return ''
    else
        return s

}


