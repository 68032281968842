import React, {Component} from "react";
import { TableContainer, Table, TableBody,  TableHead, TableRow, Paper } from '@material-ui/core';
import {Cell} from './FloatingActionButton';

import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";

// a little function to help us with reordering the result
const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default class EditRecipeStepsDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: props.steps
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(drop) {
        if (!drop.destination) {
            return;
        } // dropped outside the list
        const from = drop.source.index
        const to = drop.destination.index
        if (to === from) return
        const it = this.state.items[from]

        console.log(`dragEnd ${from} to  ${to}`)
        const items = reorder(this.state.items, from, to);
        //TODO reindex dependencies
        items.forEach((s, ix) => {
            s.id = ix
        })
        if (from > to) { // up
            const newAfter = it.after.filter((s) => s < to)
            console.log(`drop/up/adjust/it/after ${it.after} ->  ${newAfter}`)
            it.after = newAfter
            for (var i = from + 1; i < items.length; i++) {
                const intermediate = items[i]
                intermediate.after = intermediate.after.map((s) => s === from ? to : s)
            }
            for (i = to + 2; i < items.length; i++) { // to + 1 cannot be affected, because all after values are < to
                const intermediate = items[i]
                intermediate.after = intermediate.after.map((s) => s < from && s >= to ? s + 1 : s)
            }


        } else if (from < to) {
            for (i = from; i < to; i++) {
                const intermediate = items[i]
                intermediate.after = intermediate.after.filter((s) => s !== from)
                intermediate.after = intermediate.after.map((s) => s > from && s < to ? s - 1 : s)
            }
            for (i = to + 1; i < items.length; i++) {
                const intermediate = items[i]
                //intermediate.after = intermediate.after.map((s)=> s === from? to:s)
                intermediate.after = intermediate.after.map((s) => s === from ? to : s)
            }
            //it.after = it.after.filter((s) => s.id > to)
        }


        this.setState({items});
        this.props.itemsShifted(items)
    }

    render() {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{backgroundColor:'lightgray'}}>
                            <Cell>Nr</Cell>
                            <Cell style={{width:'65%'}}>Text</Cell>
                            <Cell align="right" style={{width:'25%'}}>Dauer</Cell>
                            <Cell style={{width:'10%'}} align="right">Vorher</Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                        {this.state.items.map((item, index) => (
                        <TableRow component={DraggableComponent(item.id, index)} key={item.id} onClick={() => this.props.editStep(item.id, false)} onDoubleClick={() => this.props.editStep(item.id, true)} >
                            <Cell scope="row">{index+1}</Cell>
                                <Cell>{item.text}</Cell>
                                <Cell align="right">{item.duration}</Cell>
                                <Cell align="right">{this.formatDependencies(item.after)}</Cell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    formatDependencies(deps) {
        return deps.reduce((str, d) => str += (d + 1) + ' ', '');
    }


}


const DraggableComponent = (id, index) => (props) => {
    return (
        <Draggable draggableId={`${id}`} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    );
};

const DroppableComponent = (onDragEnd: (result, provided) => void) => (props) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'1'} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    );
                }}
            </Droppable>
        </DragDropContext>
    );
};

