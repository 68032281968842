import React, {Component} from 'react';
import './App.css';
import AppBarCP from './AppBarCP';

export class About extends Component {
    render() {
        return (
            <div>
                <AppBarCP back={this.props.back} text='Über Cookpilot'/>
                <div className='Body'>
                    <p>Cookpilot ist ein Projekt der Qint Software GmbH</p>
                    <p>Aktuelle Version: 3.0</p>
                </div>
            </div>
        );
    }
}
export default About
