import React, {Component, Fragment} from 'react';
import AppBarCP from './AppBarCP';
import {saveSteps, saveTask, deleteImageFromDb, storeImage} from './db.js'
import {Box, Checkbox, FormControlLabel, Tab, Tabs, TextField} from '@material-ui/core';
import {DefaultAddButton} from './FloatingActionButton';
import EditRecipeStepsDisplay from './EditRecipeStepsDisplay';
import Cook from './Cook';
import EditStep from "./EditStep";
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import DescriptionIcon from '@material-ui/icons/Description';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import {TabContext, TabPanel} from '@material-ui/lab';
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import DeleteIcon from "@material-ui/icons/Delete";

export default class EditRecipe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: this.props.steps,
            name: this.props.recipe.name,
            source: this.props.recipe.source??'',
            description: this.props.recipe.description??'',
            published: this.props.recipe.public ?? false,
            ingredients: this.props.recipe.ingredients ?? '',
            step: null,
            tab: 'Description',
            image: this.props.recipe.image,
            thumbnail: this.props.recipe.thumbnail,
            imageKey: this.props.recipe.imageKey

        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.modified = false
        this.fullTextChanged = false
        this.stepCount = this.props.steps.length
        this.images = []
        this.imagesToSave = 0
        this.publishChange = this.publishChange.bind(this)
        this.editStepStart = this.editStepStart.bind(this)
        this.itemsShifted = this.itemsShifted.bind(this)
        this.testRecipe = this.testRecipe.bind(this)
        this.menuHandler = this.menuHandler.bind(this)
        this.addRow = this.addRow.bind(this)
        this.tabChange = this.tabChange.bind(this)
        this.deleteStep = this.deleteStep.bind(this)
        this.imageUploaded = this.imageUploaded.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    render() {
        if (this.state.testRecipe) {
            return <Cook recipe={this.state.testRecipe} back={() => this.setState({testRecipe: null})}/>
        } else {
            return (
                <div>
                    <AppBarCP back={this.back} text='Rezept erfassen' appMenu={this.appMenuItems}
                              menuHandler={this.menuHandler}/>
                    <div className='Body'>
                        <TextField id='name' autoFocus={true} fullWidth={true} label='Name' value={this.state.name}
                                   onChange={this.handleInputChange}/>
                        <Tabs value={this.state.tab} onChange={this.tabChange}>
                            <Tab icon={<DescriptionIcon/>} aria-label="Beschreibung" value='Description'/>
                            <Tab icon={<LocalGroceryStoreIcon/>} aria-label="Zutaten" value='Ingredients'/>
                            <Tab icon={<FormatListNumberedIcon/>} aria-label="Schritte" value='Steps'/>
                            <Tab icon={<TextFieldsIcon/>} aria-label="Volltext" value='Fulltext'/>
                            <Tab icon={<HelpOutlineIcon/>} aria-label="Schritte" value='Help'/>
                        </Tabs>
                        <TabContext value={this.state.tab}>
                            <TabPanel value='Description'>
                                <div><TextField id='description' fullWidth={true} label="Beschreibung" multiline
                                                value={this.state.description} onChange={this.handleInputChange}/></div>
                                <div><TextField id='source' fullWidth={true} label="Webadresse"
                                                value={this.state.source}
                                                onChange={this.handleInputChange}/></div>

                                <FormControlLabel className='TextField' value="published" style={{flexGrow: 1}}
                                                  control={<Checkbox checked={this.state.published} color="primary"
                                                                     onChange={this.publishChange}/>}
                                                  label="&Ouml;ffentlich"/>
                                <Box display='flex'>
                                    <Box flexGrow={1}>
                                        {this.showImage()}
                                        <input hidden accept="image/*" id="icon-button-photo" onChange={this.imgFileSelected}
                                               type="file"/>
                                        <label htmlFor="icon-button-photo">
                                            <IconButton color="primary" component="span"> <PhotoCamera/> </IconButton>
                                        </label>
                                    </Box>
                                </Box>

                            </TabPanel>
                            <TabPanel value='Steps'>
                                {this.getEditRecipeStepTable()}
                            </TabPanel>
                            <TabPanel value='Ingredients'>
                                <div><TextField id='ingredients' fullWidth={true} label="Zutaten" multiline rows={24}
                                                value={this.state.ingredients} onChange={this.handleInputChange}
                                                variant="outlined"/></div>
                            </TabPanel>
                            <TabPanel value='Fulltext'>
                                <div><TextField id='fulltext' fullWidth={true} label="Volltext" multiline rows={24}
                                                value={this.state.fulltext} onChange={this.handleInputChange}
                                                onPaste={this.onPaste}
                                                variant="outlined"/></div>
                            </TabPanel>
                            <TabPanel value='Help'>
                                {this.editHelp()}
                            </TabPanel>
                        </TabContext>
                    </div>
                </div>
            )
        }
    }
    getEditRecipeStepTable() {
        if (this.state.step) {
            return (
                <EditStep step={this.state.step} steps={this.state.steps} back={this.stepEditDone}
                          addRow={this.addRow} deleteStep={this.deleteStep} onParentBack={f=>{this.saveStep = f}}/>)
        } else
            return <Fragment>
                <EditRecipeStepsDisplay steps={this.state.steps} editStep={this.editStepStart}
                                        itemsShifted={this.itemsShifted}/>
                <DefaultAddButton onClick={this.addRow}/>
            </Fragment>
    }


    tabChange(ev, val) {
        this.setState({tab: val})
        if (this.modified) {
            this.saveRecipe()
        }
        if (val === 'Fulltext') {
            this.setState({fulltext: this.createFulltext()})
        } else if (this.fullTextChanged) {
            const newSteps = parseFullText(this.state.fulltext)
            saveSteps(this.props.recipe, newSteps)
            this.setState({steps: newSteps})
            this.fullTextChanged = false
            this.stepCount = newSteps.size
        }
    }

    createFulltext() {
        var ret = ''
        this.state.steps.forEach((t) => {
            ret += '\n'
            ret += t.text
            if (t.duration) ret += ' ' + t.duration + 'm '
            if (t.image) ret += ' i' + this.imgRef(t.image)
            if (t.after) t.after.forEach((d) => ret += ' #' + (d + 1))
            if (t.hint) ret += '\n ' + t.hint
        })
        return ret.substring(1)
    }

    imgRef(img) {
        this.images.push(img)
        return this.images.length
    }

    publishChange = (target) => {
        console.log("EditRecipe/publishChange " + target.target.checked)
        this.modified = true
        this.setState({published: target.target.checked})
    }


    formatDependencies(deps) {
        return deps.reduce((str, d) => str += (d + 1) + ' ', '');
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.id;
        this.setState({[name]: target.value});
        this.modified = true;
        this.fullTextChanged = name === 'fulltext'
    }

    itemsShifted(items) {
        this.setState({steps: items})
        saveSteps(this.props.recipe, items)
    }

    addRow() {
        const newStep = {
            'id': this.stepCount,
            'text': '',
            'duration': '',
            'hint': '',
            'after': []
        }
        this.setState({step: newStep})
        this.stepCount++
        console.log("addRow " + JSON.stringify(newStep))
    }

    editStepStart(id) {
        console.log("setEditStart id=" + id)
        this.setState({step: this.state.steps[id]})
    }

    stepEditDone = (newStep) => {
        if (newStep !== null) { // null indicates, that there was no change
            const steps = this.state.steps
            const nid = newStep.id
            const isNew = !this.state.steps.some((s) => s.id === nid)
            if (isNew) {
                steps.push(newStep)
            } else {
                steps[nid] = newStep
                if (newStep.after && newStep.after.length) {
                    const maxPred = newStep.after.reduce((max, s) => s > max ? s : max)
                    if (maxPred > nid) {// downward dependency: shift newStep down
                        for (var i = nid; i < steps.length; i++) {
                            const intermediate = steps[i]
                            intermediate.after = intermediate.after.filter((s) => s !== nid)
                            intermediate.after = intermediate.after.map((s) => s >= nid && s <= maxPred ? s - 1 : s)
                            intermediate.id = intermediate.id - 1
                        }
                        const [removed] = steps.splice(nid, 1)
                        steps.splice(maxPred, 0, removed)
                        newStep.id = maxPred
                    }
                }
            }
            saveSteps(this.props.recipe, steps)
            this.setState({steps})
        }
        this.setState({step: null})

    }

    testRecipe() {
        this.saveRecipe()
        const testRecipe = {...this.props.recipe, steps: this.state.steps}

        this.setState({testRecipe})
    }

    saveRecipe() {
        if (this.modified) {
            this.props.recipe.name = this.state.name
            this.props.recipe.description = this.state.description ?? ''
            this.props.recipe.source = this.state.source ?? ''
            this.props.recipe.ingredients = this.state.ingredients ?? ''
            this.props.recipe.public = this.state.published ?? false
            if (this.state.thumbnail) this.props.recipe.thumbnail = this.state.thumbnail; else delete this.props.recipe.thumbnail
            if (this.state.image) this.props.recipe.image = this.state.image; else delete this.props.recipe.image;
            if (this.state.imageKey) this.props.recipe.imageKey = this.state.imageKey; else delete this.props.recipe.imageKey;


            saveTask(this.props.recipe)
            this.modified = false
        }
    }

    back = () => {
        if (this.state.step)
            this.saveStep()
        this.saveRecipe()
        this.props.finished()
    }

    deleteStep() {
        const delId = this.state.step.id
        this.state.steps.splice(delId, 1)
        for (let ix = delId; ix < this.state.steps.length; ix++) {
            const step = this.state.steps[ix]
            step.id = ix
            const newDependencies = []
            step.after.forEach(it=>{
                if (it < delId)
                    newDependencies.push(it)
                else if (it > delId)
                    newDependencies.push(it-1)
                // it = delId => it is the removed element and skipped (i.e. refs to it deleted)
            })
            step.after = newDependencies
        }
        saveSteps(this.props.recipe, this.state.steps)
        if (this.state.imageKey)
            deleteImageFromDb(this.state.imageKey)
        this.setState({steps: this.state.steps, step:null})
    }

    imageUploaded(prop, url, imageKey) {
        if (this.state.imageKey && imageKey !== this.state.imageKey)
            deleteImageFromDb(this.state.imageKey)
        this.imagesToSave--
        this.setState({[prop]: url, imageKey, waitForImageSave: this.state.waitForImageSave && this.imagesToSave > 0})
    }

    showImage() {
        if (this.state.thumbnail) {
            return <Fragment><img src={this.state.thumbnail} alt="Bild des Gerichtes"/><IconButton color="primary" component="span" onClick={this.deleteImage}> <DeleteIcon/> </IconButton></Fragment>
        }
    }
    deleteImage(){
        this.modified = true
        deleteImageFromDb(this.state.imageKey)
        this.setState({imageKey:null, thumbnail: null, image:null})
    }

    imgFileSelected = ({target}) => {
        const file = target.files[0]
        console.log("EditStep/imgFileSelected " + file.name)
        this.imagesToSave += 2 // need to wait for 2 images (image + thumbnail).
        this.modified = true
        storeImage(file, this.imageUploaded)
    };



    editHelp() {
        return (
            <div>
                <p>Ein Rezept besteht aus der Beschreibung, den Zutaten und den Arbeitschritten.</p>
                <dl>
                    <dt>Beschreibung:</dt>
                    <dd>Hier kannst Du das Rezept beschreiben, die Quelle angeben, ein Bild hochladen und das Rezept
                        ver&ouml;ffentlichen.
                    </dd>
                    <dt>Zutaten:</dt>
                    <dd>Hier kannst Du die Zutaten auflisten.</dd>
                    <dt>Schritte:</dt>
                    <dd>Hier werden die einzelnen Arbeitschritte eingegeben. Ein Schritt sollte eine m&ouml;glichst kurze
                        Anweisung enthalten. Ausserdem kannst Du einen l&auml;ngere Erl&auml;uterung eingeben und ein Bild
                        beif&uuml;gen.
                        Dann kannst Du angeben wie lange ein Schritt dauert, wenn etwas kochen oder backen soll.
                        Besonders n&uuml;tzlich wird deine Beschreibung, wenn Du angibst wie die Schritte voneinander
                        abh&auml;ngen. Das kannst Du tun indem du ankreuzt, welche anderen Schritte vorher fertig sein
                        m&uuml;ssen, bevor dieser Schritt angefangen werden kann. Das erlaubt es Cookpilot die Schritte noch
                        &uuml;bersichtlicher anzuzeigen.
                        Du kannst die Reihenfolge der Schritte ver&auml;ndern indem Du sie aufw&auml;rts oder abw&auml;rts schiebst.
                    </dd>
                </dl>
            </div>)
    }

    styles = {color: 'red'}

    appMenuItems = ["Rezept testen"]

    menuHandler(menuItem) {
        this.testRecipe()
    }
}

function parseFullText(fulltext)
{
    const t = fulltext.split('\n')
    const steps = []
    let lix = 0
    let stix = 0
    while (lix <= t.length-1){
        if (isBlank(t[lix])) {
            lix++
            continue
        }
        const newStep = parseFulltextLine(t[lix], stix)
        stix++
        lix++
        let hint = ''
        while (lix <= t.length -1 && t[lix].startsWith(" ")) { // hint
            hint = hint + t[lix]
            lix++
        }
        if (hint.length > 0)
            newStep.hint = hint.substring(1)
        steps.push(newStep)
    }
    console.log("EditRecipe/parseFulltext " + JSON.stringify(steps))
    return steps
}

function parseFulltextLine(l, id)
{
    let {duration, lastIndex, textEnd} = extracted(l);

    //TODO remap images
    //const regI = new RegExp(' i(\\d+)', 'g')
    //const textI = regI.exec(l)
    //let imageIx = textI ? parseInt(textI[1]) : null

    const regA = new RegExp(' #(\\d+)', 'g')
    regA.lastIndex = lastIndex
    let after = []
    let a
    while ((a = regA.exec(l)) !== null) {
        console.log(`Found ${a[0]}. Next starts at ${regA.lastIndex}.`);
        after.push(parseInt(a[1])-1)
        if (textEnd === l.length+1) {
            textEnd = regA.lastIndex-a[0].length
        }
    }

    return {text: l.substring(0, textEnd), duration, after, id}
}

function extracted(l,lastIndex) {
    const regD = new RegExp(' (\\d+)m(in)?', 'g')
    regD.lastIndex = lastIndex
    const textD = regD.exec(l)
    let duration = textD ? parseInt(textD[1]) : null
    let textEnd = regD.lastIndex > 0 ? regD.lastIndex - textD[0].length : l.length + 1
    return {duration, lastIndex:regD.lastIndex, textEnd};
}

function isBlank(s)
{
    if (!s || s.length === 0) return true
    for (let i= 0; i <= s.length -1; i++) {
        if (s.charAt(i) !== ' ') return false
    }
    return true
}


