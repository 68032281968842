import ButtonBase from '@material-ui/core/ButtonBase'
import React, {Component} from 'react'
import './App.css'
import AppBarCP from './AppBarCP'
import Cook from './Cook'
import {loadSteps, loadTasks} from './db.js'

export class CookBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: null,
            loading: true,
            recipeList: null,
        }
        if (this.props.active) {
            this.loadRecipe(this.props.active.taskId)
        }
    }

    componentDidMount() {
        loadTasks(this);
    }

    render() {
        if (this.state.recipe) {
            //console.log("Cookbook cooking: " + this.state.recipe.id);  
            return <Cook recipe={this.state.recipe} back={this.showRecipes}/>
        } else if (this.state.loading) {
            //console.log("cookbook: loading");  
            return <span>Startet...</span>;
        } else if (this.state.recipes) {
            console.log("cookbook: renderRecipeList " + this.state.recipes.size);
            return this.renderRecipeList();
        }
        else return <span>No Recipes. </span>;
    }
    //<FloatingActionButton/>	
      
 
    renderRecipeList() {
        return (
            <div >
                <AppBarCP back={this.out} text='Was willst Du heute kochen?'/>
                <div className='Body'>
                    {Array.from(this.state.recipes, ([k,v]) => <p key={k} ><ButtonBase variant="text" onClick={() => this.loadRecipe(k)} style={{textAlign: 'left',}}>{v.name}</ButtonBase></p>)}
                </div>
            </div>
        );
    }

    loadRecipe = (rId) => {
        this.setState({loading: true});
        loadSteps(rId, this)
    };

    recipeLoaded(recipeData, steps) {
        console.log("CookBook/recipeLoaded ", recipeData, steps)
        if (this.props.active) {
            for (var i = 0; i < steps.length; i++) {
                steps[i].state = this.props.active.status[i]
            }
        }
        recipeData.steps = steps
        this.setState({ loading: false, recipe: recipeData})
    }

    recipeListLoaded(recipeList) {
        console.log("recipeListLoaded " + recipeList.size)
        this.setState({ loading: false, recipe: null, recipes: recipeList})
    }

    showRecipes = () => this.setState({recipe: null});
    
    out = () => this.props.back();
}

export default CookBook;
    
