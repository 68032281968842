import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {TableCell} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  }}));

export function DefaultAddButton(propsa) {
  const classes = useStyles();
  return (
    <div>
      <Fab color="primary" aria-label="Add" className={classes.fab} onClick={propsa.onClick}>
        <AddIcon/>
      </Fab>
    </div>
  );
}

  export function DefaultButton(propsa) {
    const classes = useStyles();
    return (
      <div>
        <Fab color="primary" aria-label="Add" className={classes.fab} onClick={propsa.onClick}>
          {propsa.children}
        </Fab>
      </div>
    );
  }

export const Cell = withStyles({
  root: {
    paddingLeft: 7,
    paddingTop: 12,
    paddingBottom:11,
    paddingRight: 7,
  }
})(TableCell);
export const NCell = withStyles({
  root: {
    paddingLeft: 7,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 7,
  }
})(TableCell);

