import React, {Component} from 'react';
import './App.css';
import {CookStep} from './CookStep'
import {saveCookState, clearActiveSteps, createSessionKey} from './db'
import AppBarCP from './AppBarCP';
import {Tab, Tabs, Paper} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {TabContext, TabPanel} from "@material-ui/lab";


export class Cook extends Component {
    ingredients = []

    constructor(props) {
        super(props);
        console.log('Cook: ' + this.props.recipe.name);
        this.steps = this.props.recipe.steps;
        if (!this.steps[0].state) { // not already active
            this.steps.forEach((s) => {
                if (s.after.length === 0) s.state = 0; //enabled
                else s.state = 1; // blocked
            })
        }
        console.log("Cook/init " + JSON.stringify(this.props.recipe.ingredients))
        if (this.props.recipe.ingredients)
            this.ingredients = this.props.recipe.ingredients.split("\n")

        console.log("Cook/init " + JSON.stringify(this.ingredients))
        this.state = {tab: 'Description'}
        this.lastDone = Date.now()
        this.timing = {timing:new Array(this.steps.length), date: this.lastDone}
        this.timing.timing.fill(-1)
        this.sessionId = createSessionKey(this.props.recipe.id)
        this.tabChange = this.tabChange.bind(this)
    }

    render() {
        return (
            <div>
                <AppBarCP back={this.out}>{this.props.recipe.name}</AppBarCP>
                <div className='Body'>
                    <Tabs value={this.state.tab} onChange={this.tabChange}>
                        <Tab icon={<DescriptionIcon/>} aria-label="Beschreibung" value='Description'/>
                        <Tab icon={<FormatListNumberedIcon/>} aria-label="Schritte" value='Steps'/>
                        <Tab icon={<HelpOutlineIcon/>} aria-label="Schritte" value='Help'/>
                    </Tabs>
                    <TabContext value={this.state.tab}>
                        <TabPanel value='Description'>
                            <p>{this.props.recipe.description}</p>
                            {(this.props.recipe.source !== null && this.props.recipe.source !== '') ?
                                (<span>Internet-Quelle: <a href={this.props.recipe.source}>{domain_of(this.props.recipe.source)}</a></span>) : ''}
                            <Paper elevation={3} style={{padding:5, marginTop:5}}>
                                <p style={{marginTop:0}}>Zutaten:</p>
                                <ul>
                                    {this.ingredients.map((it, ix) => (<li key={ix}>{it}</li>))}
                                </ul>
                            </Paper>
                        </TabPanel>
                        <TabPanel value='Steps'>
                            {this.steps.map((step) => {
                                return (<CookStep key={step.id} step={step} done={this.done} state/>)
                            })}

                        </TabPanel>
                        <TabPanel value='Help'>
                            {this.help()}
                        </TabPanel>
                    </TabContext>
                </div>
            </div>);
    }

    done = (step) => {
        console.log('done ' + step.text);
        step.state = 2;
        var update = false;
        var complete = true
        this.steps.forEach((s) => {
            if (s.state === 1) { // blocked
                const enable = s.after.every((pre) => this.steps[pre].state === 2) // done
                if (enable) {
                    s.state = 0;
                    update = true;
                    console.log('  enabled ' + s.text);
                } // enabled
            }
            complete = complete && s.state === 2
        });
        if (update) this.forceUpdate();
        const status = this.steps.map((s) => s.state)
        if (complete)
            clearActiveSteps()
        else
            saveCookState({taskId: this.props.recipe.id, name: this.props.recipe.name, status})
    }

    out = () => {
        this.steps.forEach((s) => {
            if (s.timer) {
                clearInterval(s.timer)
                s.timer = null
            }
        });

        this.props.back()
    }

    tabChange(ev, val) {
        this.setState({tab: val})
    }

    help() {
        return (
            <div>
                <p>So geht es: Die einzelnen Schritte, werden in einer detaillierten Liste angezeigt. Die Farben sagen
                    Dir wo Du stehst.</p>
                <ul>
                    <li>Hervorgehobene Schritte sind als n&auml;chstes dran. Markiere sie durch antippen wenn sie fertig
                        sind.
                    </li>
                    <li>Dunkelgraue Schritte k&ouml;nnen erst sp&auml;ter begonnen werden.</li>
                    <li>Blau unterstrichene Schritte dauern einige Zeit. (Z.B. etwas soll kochen) Starte die Uhr durch
                        antippen. Dann wird Dir die Restzeit und ein Fortschrittsbalken angezeigt
                    </li>
                    <li>Graue Schritte sind erledigt.</li>
                </ul>
            </div>)
    }

}
function domain_of(s) {
    const regD = new RegExp('://(www\\.)?([^/]+)', 'g')
    const match = regD.exec(s)
    return match && match[2]?match[2]: 'Link'

}


export default Cook;
